<template>
	<basePopup :title="title" cancelText="닫기">
		<div>
			<h6>기본 정보</h6>
			<vtb :data="baseInfo">
				<template #managerList>
					<vSelect class="w-49" v-model="input.manager" :clearable="false" :options="managerOpts" />
				</template>
			</vtb>

			<h6>SNS 정보</h6>
			<vtb :data="snsInfo" />

			<collapse title="주소 정보">
				<vtb :data="addressInfo" oneColumn titleSize="2" />
			</collapse>

			<collapse title="추가 정보">
				<vtb :data="etcInfo" />
			</collapse>

			<div v-if="hottistSection">
				<div class="w-1/2 inline-block">
					<h6 class="w-1/1">핫티스트 구분</h6>
					<b-form-radio
						v-for="v in getFilterOpts(hottistTypeOpts)"
						class="pr-4 inline-block top-1.5"
						v-model="input.hottistType"
						name="hottistTypeRadio"
						:value="v.value"
					>
						<span>{{ v.label }}</span>
					</b-form-radio>
				</div>

				<div class="w-1/2 inline-block">
					<h6>핫티스트 등급</h6>
					<vSelect v-model="input.hottistClass" :clearable="false" :options="hottistClassOpts" />
				</div>

				<div class="w-1/2 inline-block">
					<h6 class="w-1/1">콘텐츠 퀄리티</h6>
					<b-form-radio
						v-for="v in getFilterOpts(contentQualityOpts)"
						class="pr-4 inline-block top-1.5"
						v-model="input.contentQuality"
						name="contentQualityRadio"
						:value="v.value"
					>
						<span>{{ v.label }}</span>
					</b-form-radio>
				</div>
				<div class="w-1/2 inline-block">
					<h6 class="w-1/1">&nbsp;</h6>
					<b-form-checkbox class="top-1 ml-2" v-model="input.isSales">판매계정 여부</b-form-checkbox>
				</div>
				<div v-if="pageKey == 'hottistList'" class="w-1/2 mt-3">
					<h6 class="w-1/1">해시태그</h6>
					<b-form-input
						class="w-80 mr-3 form-control inline-block"
						v-model="input.hashTag"
						name="hashTag"
						placeholder="해시 태그를 등록해 주세요."
						@keypress.enter="inputHashTag"
					/>
					<b-button class="btn w-30 h-9" @click="inputHashTag" v-text="'등록'" />

					<div v-if="hashTagList" class="flex mt-3">
						<b-badge
							v-for="(v, i) in hashTagList"
							class="h-6.5 lh-5 -top-0.5 cursor-pointer mr-1"
							pill
							variant="success"
							># {{ v }}
							<b-icon class="mx-0.5" @click="hashTagList.splice(i, 1)" icon="x" scale="2" />
						</b-badge>
					</div>
				</div>

				<h6 class="mt-4">콘텐츠 카테고리</h6>
				<selectList :itemList="getFilterOpts(contentCategoryOpts)" :pr="ths" />
			</div>
		</div>

		<template #footer>
			<div class="absolute top-0.5 left-3 flex">
				<vSelect class="w-40" v-model="withdraw.reason" :clearable="false" :options="withdraw.Opts" />
				<input
					class="w-80 form-control mx-4"
					v-model.trim="withdraw.memo"
					name="withdrawMemo"
					type="text"
					placeholder="탈퇴 메모"
				/>
				<b-button class="px-10" @click="withDrawMember" variant="purple" size="sm">회원탈퇴</b-button>
			</div>
			<b-button class="float-right mr-4 px-10 my-2" @click="save" size="sm" v-text="getOkText()" />
			<b-button class="float-right mr-4 px-10 my-2" @click="isShow = false" variant="info" size="sm">
				닫기
			</b-button>
		</template>
	</basePopup>
</template>

<script>
import basePopup from 'comp/local/basePopup'
import collapse from 'comp/collapse'
import selectList from 'comp/local/selectList'

const defInput = {
	hottistClass: 'd',
	hottistType: '',
	contentQuality: 30,
	isSales: false,
	currentInstaId: '',
	follower: 0,
	manager: '',

	postCode: '',
	address: '',
	address2: '',
}

export default {
	components: { basePopup, collapse, selectList },
	props: {
		hottistTypeOpts: { default: false },
		contentQualityOpts: { default: false },
		contentCategoryOpts: { default: false },
		userTypeOpts: { default: false },
		pageKey: { default: false },
	},
	data() {
		return {
			title: '',
			isEdit: false,
			readOnly: false,
			isShow: false,
			hottistClassOpts: ['1', 'a', 'b', 'c', 'd', 'e'],

			res: {},
			baseInfo: [],
			snsInfo: [],
			addressInfo: [],
			etcInfo: [],
			input: { ...defInput },

			originItem: {},

			userTypeValue: {},
			hottistSection: false,

			managerOpts: [],
			hashTagList: [],
			hashTagListMaxCt: 20,
			hashTagMaxLen: 15,

			withdraw: {
				Opts: [
					'탈퇴사유',
					'사용안함',
					'잘못 가입함',
					'딜이 승인되지 않음',
					'사용이 불편',
					'다른 서비스 이용',
					'기타',
				],
				reason: '탈퇴사유',
				memo: '',
			},
		}
	},
	methods: {
		open(item, isEdit = false, isReadOnly = false) {
			this.isEdit = isEdit
			this.readOnly = isReadOnly
			this.originItem = item
			this.hashTagList = []
			this.isShow = true
			this.res = {}

			this.input = { ...defInput }
			this.withdraw.reason = '탈퇴사유'
			this.withdraw.memo = ''
			this.baseInfo = []
			this.snsInfo = []
			this.addressInfo = []
			this.etcInfo = []
			postApi('api/backoffice/hottistSelect/hottistDetailInfo', {
				memberSeq: item.memberSeq,
			}).then(res => {
				Object.keys(res).map(k => (res[k] = res[k] ?? ''))

				this.userTypeValue = this.userTypeOpts.filter(userType => userType['value'] === res.userType)

				// 핫티스트이거나 핫티스트 심사 화면 인 경우 hottistSection 표출
				let selectedUserTypeValue = this.userTypeValue[0]
				this.hottistSection =
					selectedUserTypeValue.value !== 'L' ||
					(selectedUserTypeValue.value === 'L' && this.pageKey != 'hottistList')
						? true
						: false

				this.title = `${res.name} / ${res.hottId} / ${res.currentInstaId}`
				this.input.follower = res.follower

				// null이 텍스트로 입력되어 있는 데이터 null처리(잘못 된 데이터로 마이그레이션 예정)
				let instaId = res.snsLink.substr(res.snsLink.lastIndexOf('/') + 1)
				res.snsLink = instaId === 'null' ? null : res.snsLink

				if (res.hashTag) this.hashTagList = res.hashTag.split(',')

				this.baseInfo = [
					{ title: '이름', cont: res.name },
					{ title: '연락처', cont: res.phone },
					{
						title: '핫트아이디',
						cont: res.hottId,
					},
					{
						title: '비밀번호',
						model: 'button',

						cont: '비밀번호 초기화',
						hottId: res.hottId,
						phone: res.phone,
						func: item =>
							postApi('/memberProcess/sendMessageForgotUser', {
								messageKeyEnum: 'RESET_PW',
								hottId: item.hottId,
								phone: item.phone,
							}).then(() => alert.s('비밀번호 초기화에 성공했습니다')),
					},
					{ title: '가입일', cont: res.registerDt },
					{ title: '승인일', cont: res.hottDealAcceptDt },
					{ title: '회원타입', cont: this.userTypeValue[0].label },
					{ title: '담당자', model: 'managerList' },
				]

				this.snsInfo = [
					{
						title: '인스타그램ID',
						model: 'input',
						obj: this.input,
						key: 'currentInstaId',
					},
					{ title: '닉네임', cont: res.nickname },
					{ title: '토큰 연결여부', cont: res.accessToken },
					{ title: '토큰 만료일자', cont: res.expiredDt },
					{ title: '홍보채널1', model: 'url', cont: res.snsLink },
					{ title: '홍보채널2', model: 'url', cont: res.snsLink2 },
					{ title: '홍보채널3', model: 'url', cont: res.snsLink3 },
					{
						title: '팔로워 수',
						model: 'input',
						obj: this.input,
						key: 'follower',
					},
				]

				this.input.postCode = res.postCode
				this.input.address = res.address
				this.input.address2 = res.address2
				this.addressInfo = [
					{ title: '주소', model: 'input', key: 'address', obj: this.input },
					{ title: '주소 상세', model: 'input', key: 'address2', obj: this.input },
					{
						title: '우편번호',
						model: 'input',
						key: 'postCode',
						obj: this.input,
					},
				]

				this.etcInfo = [
					{ title: '이메일', cont: res.email, isFull: 1 },
					{ title: '마케팅정보 수신동의', cont: res.isSmsAgree },
					{
						title: '휴대폰 본인인증',
						cont: res.isPhoneConfirm,
					},
				]

				this.input.currentInstaId = res.currentInstaId
				this.input.hottistClass = res.hottistClass
				this.input.hottistType = res.hottistType
				this.input.contentQuality = res.contentQuality ?? 30
				//이건 또 왜 null이 가능한가......
				this.input.isSales = res.isSales

				setTimeout(() => {
					//이건 왜 이렇게 빨리 들어오지;;
					this.selectList.init(res.contentCategoryList)

					if (res.adminName) this.input.manager = { label: res.adminName, value: res.adminSeq }
				})
			})

			if (!this.managerOpts.length)
				postApi('api/backoffice/hottistSelect/managerList').then(res => {
					res.push({ label: '없음', value: 0 })
					this.managerOpts = res
				})
		},
		save() {
			const input = this.input
			let apiUrl = '',
				data = {
					memberSeq: this.originItem.memberSeq,
					currentInstaId: input.currentInstaId,
					hottistType: input.hottistType,
					hottistClass: input.hottistClass,
					contentQuality: input.contentQuality,
					isSales: input.isSales ? 1 : 0,
					follower: input.follower,
					hashTag: this.hashTagList.join(','),

					postCode: input.postCode,
					address: input.address,
					address2: input.address2,

					adminSeq: getDropdownValue(input.manager) ?? 0,
				}
			//input.postCode
			//input.address
			//input.address2

			const category = this.selectList ? this.selectList.getSelectedItem() : []
			for (let i = 0; i < 3; i++) data[`contentCategory${i + 1}`] = category[i] ? category[i].value : ''

			if (this.isEdit)
				//핫티스트 정보 수정
				apiUrl = 'api/backoffice/hottistHandle/updateHottistInfo'
			else {
				//핫티스트 승인
				apiUrl = 'api/backoffice/hottistHandle/updateHottistExam'
				data.saveStatus = 'Y'
				data.userType = 'A'
				data = [data]
			}
			putApi(apiUrl, data).then(res => {
				alert.s(`핫티스트 ${this.isEdit ? '정보 수정' : '승인'}에 성공했습니다`)
				reload(this, true)
				this.isShow = false
			})
		},
		getFilterOpts(options) {
			if (Array.isArray(options)) return options.filter(v => v.value && v.value != 'All')
			return []
		},
		withDrawMember() {
			const withdrawReason = this.withdraw.reason,
				withdrawMemo = this.withdraw.memo
			if (withdrawReason == '탈퇴사유') return alert.w('탈퇴사유를 선택해주세요')
			else if (!withdrawMemo) return alert.w('탈퇴 메모를 입력해주세요')
			confirm('해당 회원은 회원탈퇴 처리되고 다시 돌릴 수 없습니다.\r\n정말 회원탈퇴를 진행하시겠습니까?', () => {
				postApi('memberProcess/withDrawMember', {
					memberSeq: this.originItem.memberSeq,
					withdrawReason,
					withdrawMemo,
				}).then(() => {
					alert.s('회원탈퇴에 성공했습니다')
					this.isShow = false
					reload(this, true)
				})
			})
		},
		getOkText() {
			if (!this.isEdit) return '승인'
			if (!this.readOnly) return '수정'
			return ''
		},
		inputHashTag() {
			const tagList = this.hashTagList,
				val = this.input.hashTag?.trim()

			if (!val) return alert.w('해시 태그를 입력해 주세요.')
			if (tagList.length >= this.hashTagListMaxCt)
				return alert.w(`태그는 최대 ${this.hashTagListMaxCt}개까지 등록 가능합니다.`)
			if (tagList.includes(val)) return alert.w('동일한 태그가 존재 합니다.')
			if (val.length > this.hashTagMaxLen) return alert.w(`태그 글자수는 ${this.hashTagMaxLen}자내로 가능합니다.`)
			if (val.indexOf(',') != -1) return alert.w('해시 태그로 ,는 입력할 수 없습니다.')

			this.hashTagList.push(val)
			this.input.hashTag = ''
		},
	},
}
</script>
